<template>
  <div>
    {{ value.join(", ") }}
  </div>
</template>

<script>
export default {
  name: "service-included",

  components: {},

  mixins: [],

  props: {
    value: {
      type: Array,
      default: [],
    },
  },

  data() {
    return {};
  },

  setup() {},

  created() {},

  methods: {},

  watch: {},
};
</script>
