var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-wrapper-table"},[_c('div',{staticClass:"page-wrapper-table-header"},[_c('div',{staticClass:"page-wrapper-table-header-left"},[_c('base-input',{staticClass:"search",attrs:{"type":"search","prepend-icon":"far fa-search","placeholder":_vm.$t('COMMON.SEARCH'),"clearable":""},model:{value:(_vm.query),callback:function ($$v) {_vm.query=$$v},expression:"query"}}),(!_vm.filterStatus)?_c('el-select',{attrs:{"placeholder":_vm.$t('COMMON.STATUS')},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}},[_c('el-option',{attrs:{"value":0,"label":_vm.$t('COMMON.ALL_STATUSES')}}),_vm._l((_vm.statusesOptions),function(value,key){return _c('el-option',{key:key,attrs:{"value":key,"label":value}})})],2):_vm._e(),(
          !_vm.filterOrganization &&
          !_vm.filterRecipient &&
          !_vm.filterSalesOrder &&
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS)
        )?_c('organization-selector',{on:{"organizationChanged":(organizationId) => (_vm.selectedOrganization = organizationId)}}):_vm._e(),(
          !_vm.filterSalesOrder &&
          _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS)
        )?_c('base-input',{attrs:{"placeholder":`${_vm.$t('COMMON.LOCATIONS')}`}},[_c('locations-selector',{on:{"locationsChanged":(locations) => (_vm.selectedLocations = locations)}})],1):_vm._e(),_c('base-input',{staticClass:"dates",attrs:{"placeholder":"Dates"}},[_c('flat-picker',{staticClass:"form-control datepicker",attrs:{"config":{
            allowInput: true,
            mode: 'range',
            locale: _vm.flatPickrLocale,
          },"placeholder":"Dates"},model:{value:(_vm.dateRange),callback:function ($$v) {_vm.dateRange=$$v},expression:"dateRange"}})],1)],1),_c('div',{staticClass:"page-wrapper-table-header-right"},[_c('el-select',{staticClass:"select-primary pagination-select",attrs:{"placeholder":_vm.$t('COMMON.PER_PAGE')},model:{value:(_vm.pagination.perPage),callback:function ($$v) {_vm.$set(_vm.pagination, "perPage", $$v)},expression:"pagination.perPage"}},_vm._l((_vm.pagination.perPageOptions),function(item){return _c('el-option',{key:item,staticClass:"select-primary",attrs:{"label":item,"value":item}})}),1)],1)]),_c('div',{staticClass:"page-wrapper-table-body"},[(_vm.loading)?_c('div',{staticClass:"table-loading",attrs:{"slot":"empty"},slot:"empty"},[_c('img',{attrs:{"src":"/img/loading.gif"}})]):_vm._e(),_c('div',{staticClass:"page-wrapper-table-body-inner",style:({ visibility: _vm.loading ? 'hidden' : 'visible' })},[_c('el-table',{staticClass:"align-items-center table-flush",attrs:{"header-row-class-name":"thead-light","data":_vm.salesInvoices,"empty-text":_vm.$t('COMMON.NO_SEARCH_RESULT')},on:{"sort-change":_vm.sortChange}},[(!_vm.invoiceType || _vm.invoiceType.toLowerCase() === 'invoices')?_c('el-table-column',{attrs:{"prop":"code","min-width":"220","sortable":"custom","label":this.$t('SALES_INVOICES.SALES_INVOICES_CODE_LABEL')}}):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('SALES_INVOICES.FOLIOS_CODE_LABEL'),"prop":"code_folio","sortable":"custom","min-width":"220"}}),(
            _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_BOOKINGS) &&
            !_vm.filterSalesOrder
          )?_c('el-table-column',{attrs:{"label":_vm.$t('SALES_INVOICES.ASSOCIATED_BOOKING'),"min-width":"240"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('el-popover',{attrs:{"placement":"bottom","width":"400","trigger":"click"}},[_c('el-table',{staticClass:"permissions-list",attrs:{"data":row.items,"height":"250"}},[_c('el-table-column',{attrs:{"min-width":"150","property":"key","prop":"code","label":`${_vm.$t('BOOKINGS.BOOKING_LIST')} (${
                    row.items?.length ?? 0
                  })`},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('object-link',{attrs:{"object":row.salesInvoiceable}})]}}],null,true)})],1),_c('el-button',{staticClass:"view-permissions-button",attrs:{"slot":"reference","title":`${_vm.$t('COMMON.VIEW')} (${row.items?.length ?? 0})`},slot:"reference"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.VIEW"))+" ("+_vm._s(row.items?.length ?? 0)+") ")])],1)]}}],null,false,3553192147)}):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.DATE_TIME'),"prop":"created_at","sortable":"custom","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm.$formatDate(row.created_at, "lll"))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.CUSTOMER'),"min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('object-link',{attrs:{"object":row.recipient}})]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.EMAIL'),"min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(row.recipient.email)+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('CUSTOMERS.PHONE_SHORT'),"min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(row.recipient.phone)+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.AMOUNT'),"prop":"pricing.total","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('span',[_vm._v(" "+_vm._s(_vm.$formatCurrency(row.pricing.total))+" ")])]}}])}),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.LOCATIONS'),"sortable":"custom","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('locations',{attrs:{"locations":row.allowedLocations}})]}}],null,false,4110342845)}):_vm._e(),(
            !_vm.filterOrganization &&
            !_vm.filterSalesOrder &&
            _vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS)
          )?_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.ORGANIZATION'),"sortable":"custom","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('organization',{attrs:{"organization":row.organization}})]}}],null,false,3772690256)}):_vm._e(),_c('el-table-column',{attrs:{"label":_vm.$t('SALES_INVOICES.EXPIRATION_TIME'),"prop":"expiration_time","sortable":"custom","min-width":"220"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_vm._v(" "+_vm._s(_vm.$formatDate(row.expiration_time, "lll"))+" ")]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('COMMON.STATUS'),"prop":"status","min-width":"200"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return [_c('sales-invoice-status-badge',{attrs:{"salesInvoice":row}})]}}])}),_c('el-table-column',{attrs:{"fixed":"right","min-width":"120"},scopedSlots:_vm._u([{key:"default",fn:function({ row }){return _c('div',{staticClass:"table-actions"},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_SALES_INVOICES))?_c('el-tooltip',{attrs:{"content":_vm.$t('COMMON.VIEW'),"placement":"top"}},[_c('a',{staticClass:"table-action",staticStyle:{"cursor":"pointer"},attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.viewSalesInvoice(row)}}},[_c('i',{staticClass:"fal fa-expand-alt"})])]):_vm._e(),(
                _vm.$currentUserCan(_vm.$permissions.PERM_EDIT_SALES_INVOICES) &&
                row.status == _vm.INVOICE_STATUS_DRAFT
              )?_c('el-tooltip',{attrs:{"content":_vm.$t('COMMON.EDIT'),"placement":"top"}},[_c('a',{staticClass:"table-action",staticStyle:{"cursor":"pointer"},attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.editSalesInvoice(row)}}},[_c('i',{staticClass:"fal fa-edit"})])]):_vm._e(),(
                _vm.$currentUserCan(_vm.$permissions.PERM_DELETE_SALES_INVOICES) &&
                row.status == _vm.INVOICE_STATUS_DRAFT
              )?_c('el-tooltip',{attrs:{"content":"Delete","placement":"top"}},[_c('a',{staticClass:"table-action table-action-delete",staticStyle:{"cursor":"pointer"},attrs:{"type":"text","data-toggle":"tooltip"},on:{"click":function($event){return _vm.deleteSalesInvoice(row.id)}}},[_c('i',{staticClass:"fal fa-trash-alt"})])]):_vm._e()],1)}}])})],1)],1)]),_c('div',{staticClass:"page-wrapper-table-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('p',{staticClass:"card-category"},[_vm._v(" "+_vm._s(_vm.$t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", { from: _vm.total ? _vm.from + 1 : 0, to: _vm.to, of: _vm.total, }))+" "),(_vm.selectedRows.length)?_c('span',[_vm._v("     "+_vm._s(_vm.$t("COMMON.X_LINES_SELECTED", { count: _vm.selectedRows.length }))+" ")]):_vm._e()]),_c('base-pagination',{staticClass:"pagination-no-binvoice",attrs:{"per-page":_vm.pagination.perPage,"total":_vm.total},model:{value:(_vm.pagination.currentPage),callback:function ($$v) {_vm.$set(_vm.pagination, "currentPage", $$v)},expression:"pagination.currentPage"}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }