<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ $t("BOOKINGS.BOOKING_SPOT") }}</h3>

    <div class="col-12">
      <!-- region information de reservation -->
      <div class="mt-3">
        <h3 class="text-orange">{{ $t("BOOKINGS.BOOKING_INFORMATION") }}</h3>

        <div class="row px-2">
          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("BOOKINGS.BOOKING_NUMBER") }}
            </dt>
            <dd class="">
              <div>{{ booking.code }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("COMMON.STATUS") }}
            </dt>
            <dd class="">
              <div>{{ $t(`BOOKINGS.${booking.status}`) }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("BOOKINGS.ARRIVING_AT") }}
            </dt>
            <dd class="">
              <div>Prévue - {{ $formatDate(booking.start_at, "LL") }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("BOOKINGS.LEAVING_AT") }}
            </dt>
            <dd class="">
              <div>Prévue - {{ $formatDate(booking.end_at, "LL") }}</div>
            </dd>
          </dl>
        </div>
      </div>
      <!-- endregion -->

      <!-- region occupation -->
      <div class="mt-3">
        <h3 class="text-orange">{{ $t("COMMON.OCCUPATION") }}</h3>

        <div class="row px-2">
          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("COMMON.PEOPLE_NUMBER") }}
            </dt>
            <dd class="">
              <div>
                {{
                  booking.spot.children_capacity + booking.spot.adults_capacity
                }}
              </div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.SPOT_ADULT_CAPACITY") }}
            </dt>
            <dd class="">
              <div>{{ booking.spot.adults_capacity }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.SPOT_CHILDREN_CAPACITY") }}
            </dt>
            <dd class="">
              <div>{{ booking.spot.children_capacity ?? 0 }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.SPOT_PETS_CAPACITY") }}
            </dt>
            <dd class="">
              <div>{{ booking.spot.pets_capacity ?? 0 }}</div>
            </dd>
          </dl>
        </div>
      </div>
      <!-- endregion -->

      <!-- region information de l'emplacement -->
      <div class="mt-3">
        <h3 class="text-orange">{{ $t("BOOKINGS.SPOT_INFORMATION") }}</h3>

        <div class="row px-2">
          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.SPOT_NAME") }}
            </dt>
            <dd class="">
              <div>
                {{ booking.spot.name }}
              </div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.SPOT_NUMBER") }}
            </dt>
            <dd class="">
              <div>{{ booking.spot.code }}</div>
            </dd>
          </dl>

          <dl :class="colClass">
            <dt>{{ $t("SPOTS.SPOT_LENGTH") }}</dt>
            <dd>
              {{ spot.equipment_length }}
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.SPOT_TYPE") }}
            </dt>
            <dd class="">
              <div>{{ booking.spot.spotType?.name }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("COMMON.CATEGORY") }}
            </dt>
            <dd class="">
              <div>{{ booking.spot.category?.name }}</div>
            </dd>
          </dl>
          <!-- region superficie -->
          <dl :class="colClass" v-if="spot.spot_area && isLandSpotType">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.SPOT_AREA") }} ({{ $t("COMMON.UNIT_SQUARE_FOOT") }})
            </dt>
            <dd>
              {{ spot.spot_area }}
            </dd>
          </dl>
        </div>
        <!-- region horaire -->
        <div :class="rowClass" v-if="!isSeasonalFormType">
          <dl :class="colClass">
            <dt>{{ $t("SPOTS.SPOT_ARRIVAL_TIME") }}</dt>
            <dd>
              {{ spot.arrival_time }}
            </dd>
          </dl>

          <dl :class="colClass">
            <dt>{{ $t("SPOTS.SPOT_DEPARTURE_TIME") }}</dt>
            <dd>
              {{ spot.departure_time }}
            </dd>
          </dl>
        </div>
        <!-- endregion -->
        <!-- region lits -->
        <hr v-if="bedsIsset && (isPropertyFormType || isDefaultFormType)"/>
        <div :class="rowClass" v-if="bedsIsset && (isPropertyFormType || isDefaultFormType)">
          <dl :class="colClass" v-if="spot.beds_king_qty">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.BEDS_KING_QTY") }}
            </dt>
            <dd>
              {{ spot.beds_king_qty }}
            </dd>
          </dl>
          <dl :class="colClass" v-if="spot.beds_queen_qty">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.BEDS_QUEEN_QTY") }}
            </dt>
            <dd>
              {{ spot.beds_queen_qty }}
            </dd>
          </dl>
          <dl :class="colClass" v-if="spot.beds_double_qty">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.BEDS_DOUBLE_QTY") }}
            </dt>
            <dd>
              {{ spot.beds_double_qty }}
            </dd>
          </dl>
          <dl :class="colClass" v-if="spot.beds_twin_qty">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.BEDS_TWIN_QTY") }}
            </dt>
            <dd>
              {{ spot.beds_twin_qty }}
            </dd>
          </dl>
        </div>
        <!-- endregion -->
        <!-- region salles de bain -->
        <hr v-if="bathroomIsset && (isPropertyFormType || isDefaultFormType)"/>
        <div :class="rowClass" v-if="bathroomIsset && (isPropertyFormType || isDefaultFormType)">
          <dl :class="colClass" v-if="spot.bathroom_bathtub_qty">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.BATHROOM_BATHTUB_QTY") }}
            </dt>
            <dd>
              {{ spot.bathroom_bathtub_qty }}
            </dd>
          </dl>
          <dl :class="colClass" v-if="spot.bathroom_shower_qty">
            <dt class="font-weight-bold">
              {{ $t("SPOTS.BATHROOM_SHOWER_QTY") }}
            </dt>
            <dd>
              {{ spot.bathroom_shower_qty }}
            </dd>
          </dl>
        </div>
        <!-- endregion -->
        <!-- region services, equipement, electricite -->
        <hr v-if="(spot.services_included?.length || spot.electricity?.length || spot.allowed_equipment?.length) && isLandSpotType"/>
        <div :class="rowClass" v-if="(spot.services_included?.length || spot.electricity?.length || spot.allowed_equipment?.length) && isLandSpotType">
          <dl :class="colClass" v-if="spot.services_included?.length">
            <dt>{{ $t("SPOTS.SERVICES_INCLUDED") }}</dt>
            <dd>
              <service-included :value="spot.services_included"/>
            </dd>
          </dl>
          <dl :class="colClass" v-if="spot.electricity?.length">
            <dt>{{ $t("SPOTS.ELECTRICITY") }}</dt>
            <dd>
              <electricity :value="spot.electricity"/>
            </dd>
          </dl>
          <!-- equipements permis -->
          <dl :class="colClass" v-if="spot.allowed_equipment?.length">
            <dt>{{ $t("SPOTS.ALLOWED_EQUIPMENT") }}</dt>
            <dd>
              <allowed-equipments  :value="spot.allowed_equipment"/>
            </dd>
          </dl>
        </div>
        <!-- endregion -->
        <!-- region equipements inclus -->
        <hr v-if="spot.other_equipments?.length"/>
        <div :class="rowClass" v-if="spot.other_equipments?.length">
          <dt class="font-weight-bold">
            {{ $t("SPOTS.SPOT_INCLUDED_EQUIPMENT") }}
          </dt>
            <div class="equipments-included-group">
              <div :key="eq.id"
                  class="item width_1-4"
                  v-for="eq in spot.other_equipments">
                <ul>
                  <li v-if="eq.icon">
                    <el-image alt="icon"
                        :src="eq.icon"
                        fit="scale-down"
                        style="width: 20px; height: 20px"/>
                  </li>
                  <li>
                    {{ eq.name }}
                  </li>
                </ul>
              </div>
            </div>

        </div>
        <!-- endregion -->

        <!-- region extensions -->
        <hr v-if="(spot.passenger_extensions || spot.driver_extensions) && isLandSpotType" />
        <div :class="rowClass">
          <!-- region extensions passagers -->
          <dl :class="colClass" v-if="spot.passenger_extensions">
            <dt>{{ $t("SPOTS.PASSENGER_EXTENSIONS") }}</dt>
            <dd>
              {{ spot.passenger_extensions }}
            </dd>
          </dl>
          <!-- endregion -->

          <!-- region extensions chauffeur -->
          <dl :class="colClass" v-if="spot.driver_extensions">
            <dt>{{ $t("SPOTS.DRIVER_EXTENSIONS") }}</dt>
            <dd>
              {{ spot.driver_extensions }}
            </dd>
          </dl>
          <!-- endregion -->
        </div>
        <!-- endregion -->
        <hr/>
        <div class="row px-2">
          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("COMMON.COMMENT") }}
            </dt>
            <dd class="">
              <div v-html="booking.spot.excerpt ?? '-' "></div>
            </dd>
          </dl>
        </div>
      </div>
      <!-- endregion -->
    </div>
  </div>
</template>

<script>
import {FORM_TYPE_DEFAULT, FORM_TYPE_LAND, FORM_TYPE_PROPERTY, FORM_TYPE_SEASONAL} from "@/constants/common";
import ServiceIncluded from "@/components/ServiceIncluded.vue";
import Electricity from "@/components/Electricity.vue";
import AllowedEquipments from "@/components/AllowedEquipments.vue";
import { Image } from "element-ui";


export default {
  name: "booking-view-spot",

  props: {
    booking: {
      type: Object,
      required: true,
    }
  },

  components: {AllowedEquipments, [Image.name]: Image, Electricity, ServiceIncluded },

  data() {
    return {
      spot: this.booking.spot,
      colClass: "col-md-6 p-0 pb-4",
      rowClass: "row px-2",
    } ;
  },

  computed: {
    isDefaultFormType: function () {
      return this.spot.category.form_type === FORM_TYPE_DEFAULT;
    },
    isPropertyFormType: function () {
      return this.spot.category.form_type === FORM_TYPE_PROPERTY;
    },
    isLandFormType: function () {
      return this.spot.category.form_type === FORM_TYPE_LAND;
    },
    isSeasonalFormType() {
      return this.spot.category.form_type === FORM_TYPE_SEASONAL;
    },
    isLandSpotType() {
      return this.isSeasonalFormType || this.isLandFormType ;
    },
    bedsIsset: function () {
      return (
          this.spot.beds_king_qty ||
          this.spot.beds_queen_qty ||
          this.spot.beds_double_qty ||
          this.spot.beds_twin_qty
      );
    },
    bathroomIsset: function() {
      return (this.spot.bathroom_bathtub_qty || this.spot.bathroom_shower_qty) ;
    }
  },

  created() {},

  methods: {},

  mounted() {},

  watch: {
    booking(booking) {},
  },
};
</script>

<style lang="scss">
.text-orange {
  --darken-primary: #{darken(#ef8152, 10%)};
  color: --darken-primary;
}
</style>
