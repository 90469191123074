<template>
  <div>
    <div class="page-wrapper-table">
      <div class="page-wrapper-table-header">
        <div class="page-wrapper-table-header-left">
          <base-input
            v-model="query"
            class="search"
            prepend-icon="far fa-search"
            :placeholder="$t('COMMON.SEARCH')"
            clearable
          />
          <organization-selector
            v-if="
              !filterOrganization &&
              $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
            "
            @organizationChanged="
              (organizationId) => (selectedOrganization = organizationId)
            "
          />
        </div>
        <div class="page-wrapper-table-header-right">
          <el-select
            class="select-primary pagination-select"
            v-model="pagination.perPage"
            :placeholder="$t('COMMON.PER_PAGE')"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="page-wrapper-table-body">
        <div class="page-wrapper-table-body-inner">
          <el-table
            class="align-items-center table-flush clickable"
            header-row-class-name="thead-light"
            :data="bookings"
            @sort-change="sortChange"
            @row-click="selectRow"
          >
            <el-table-column
              :label="$t('BOOKINGS.BOOKING_CODE')"
              prop="code"
              sortable="custom"
              min-width="200"
            />
            <el-table-column
              :label="$t('SPOTS.SPOT_NUMBER')"
              prop="spot.code"
              sortable="custom"
              min-width="200"
            />
            <el-table-column
              :label="$t('COMMON.CATEGORY')"
              prop="spot.category"
              min-width="200"
            />
            <el-table-column
              :label="$t('COMMON.CUSTOMER')"
              prop="client.name"
              min-width="220"
            >
              <template v-slot="{ row }">
                <span>
                  {{
                    (row.customer?.firstname ?? "") +
                    " " +
                    (row.customer?.lastname ?? "")
                  }}
                </span>
                <small>
                  {{ row.customer?.email }}
                </small>
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('BOOKINGS.DURATION')"
              prop="duration"
              min-width="120"
            />
            <el-table-column
              :label="$t('BOOKINGS.PRICE_PER_DAY')"
              min-width="140"
            >
              <template v-slot="{ row }">
                {{ row.pricing_data?.avg }} CAD
              </template>
            </el-table-column>
            <el-table-column
              prop="end_at"
              sortable="custom"
              :label="$t('BOOKINGS.ARRIVING_AT')"
              min-width="200"
            >
              <template v-slot="{ row }">
                Prévue - {{ $timeZoneDateFormat(row.start_at, "LL") }}
              </template>
            </el-table-column>
            <el-table-column
              prop="end_at"
              sortable="custom"
              :label="$t('BOOKINGS.LEAVING_AT')"
              min-width="200"
            >
              <template v-slot="{ row }">
                Prévue - {{ $timeZoneDateFormat(row.end_at, "LL") }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('COMMON.STATUS')" min-width="140">
              <template v-slot="{ row }">
                {{ $t(`BOOKINGS.${row.status}`) }}
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.ORGANIZATION')"
              v-if="
                !filterOrganization &&
                $currentUserCan($permissions.PERM_VIEW_ANY_ORGANIZATIONS)
              "
              min-width="200"
            >
              <template v-slot="{ row }">
                <organization :organization="row.organization" />
              </template>
            </el-table-column>
            <el-table-column
              :label="$t('COMMON.CREATED_AT')"
              prop="created_at"
              sortable="custom"
              min-width="180"
            >
              <template v-slot="{ row }">
                {{ $timeZoneDateFormat(row.created_at) }}
              </template>
            </el-table-column>
            <el-table-column fixed="right" min-width="70">
              <div slot-scope="{ row }" class="table-actions">
                <!--<el-tooltip
                  :content="$t('COMMON.VIEW')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_VIEW_BOOKINGS)"
                >
                  <a
                    type="text"
                    @click="viewBooking(row)"
                    class="table-action"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-expand-alt"></i>
                  </a>
                </el-tooltip>
                <el-tooltip
                  :content="$t('COMMON.EDIT')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_EDIT_BOOKINGS)"
                >
                  <a
                    type="text"
                    @click="editBooking(row)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-edit"></i>
                  </a>
                </el-tooltip>
                <el-tooltip
                  :content="$t('COMMON.DELETE')"
                  placement="top"
                  v-if="$currentUserCan($permissions.PERM_DELETE_BOOKINGS)"
                >
                  <a
                    type="text"
                    @click="deleteBooking(row.id)"
                    class="table-action table-action-delete"
                    data-toggle="tooltip"
                    style="cursor: pointer"
                  >
                    <i class="fal fa-trash-alt"></i>
                  </a>
                </el-tooltip>-->
                <el-dropdown trigger="click">
                  <span class="el-dropdown-link">
                    <i class="far fa-ellipsis-v"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown" class="actions-dropdown">
                    <el-dropdown-item v-if="canCheckInBooking(row)">
                      <a
                        type="text"
                        @click="checkInBooking(row)"
                        class="table-action view"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-eye.svg" alt="icon" />
                        </span>
                        <span class="text">
                          {{ $t("BOOKINGS.CHECK_IN") }}
                        </span>
                      </a>
                    </el-dropdown-item>
                    <el-dropdown-item v-if="canCheckOutBooking(row)">
                      <a
                        type="text"
                        @click="checkOutBooking(row)"
                        class="table-action view"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-eye.svg" alt="icon" />
                        </span>
                        <span class="text">
                          {{ $t("BOOKINGS.CHECK_OUT") }}
                        </span>
                      </a>
                    </el-dropdown-item>
                    <!-- <el-dropdown-item
                      v-if="$currentUserCan($permissions.PERM_EDIT_BOOKINGS)"
                    >
                      <a
                        type="text"
                        @click="editBooking(row)"
                        class="table-action edit"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-edit.svg" alt="icon" />
                        </span>
                        <span class="text">
                          {{ $t("COMMON.EDIT") }}
                        </span>
                      </a>
                    </el-dropdown-item> -->
                    <el-dropdown-item
                      v-if="$currentUserCan($permissions.PERM_DELETE_BOOKINGS)"
                    >
                      <a
                        type="text"
                        @click="deleteBooking(row.id)"
                        class="table-action delete"
                        data-toggle="tooltip"
                      >
                        <span class="icon">
                          <img src="/img/kw-delete.svg" alt="icon" />
                        </span>
                        <span class="text">
                          {{ $t("COMMON.DELETE") }}
                        </span>
                      </a>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <div slot="footer" class="page-wrapper-table-footer">
        <p class="card-category">
          {{
            $t("COMMON.DISPLAY_FROM_X_TO_X_OF_X_ENTRIES", {
              from: total ? from + 1 : 0,
              to: to,
              of: total,
            })
          }}
          <span v-if="selectedRows.length">
            &nbsp; &nbsp;
            {{ $t("COMMON.X_LINES_SELECTED", { count: selectedRows.length }) }}
          </span>
        </p>
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
        />
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Option,
  Select,
  Table,
  TableColumn,
  Tooltip,
} from "element-ui";
import swal from "sweetalert2";
import "sweetalert2/dist/sweetalert2.css";
import requestErrorMixin from "@/mixins/request-error-mixin";
import { BasePagination } from "@/components";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import {
  BOOKING_STATUS_ARRIVED,
  BOOKING_STATUS_GUARANTEED,
  BOOKING_STATUS_NOT_GUARANTEED,
  BOOKING_STATUS_FINISHED,
  BOOKING_STATUS_PENDING,
} from "../../../../../constants/common";

export default {
  name: "booking-list-table",

  components: {
    BasePagination,
    OrganizationSelector,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  props: {
    filterOrganization: {
      type: String,
      default: null,
      description: "Organization id",
    },
    filterBookingableType: {
      type: String,
      default: null,
      description: "Bookingable class",
    },
    filterBookingableId: {
      type: String,
      default: null,
      description: "Bookingable id",
    },
  },

  data() {
    return {
      query: null,
      selectedRows: [],
      sort: "-updated_at",
      total: 0,
      pagination: {
        perPage: 20,
        currentPage: 1,
        perPageOptions: [20, 50, 100, 500],
      },
      bookings: [],
      loading: true,
      selectedOrganization: null,
    };
  },

  computed: {
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },

    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
  },

  watch: {
    query: {
      handler: "getListDebounced",
      immediate: true,
    },
    pagination: {
      handler: "getList",
      immediate: false,
      deep: true,
    },
    filterOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
    selectedOrganization: {
      handler: "getListDebounced",
      immediate: true,
    },
  },

  methods: {
    getListDebounced: _.debounce(function () {
      this.getList();
    }, 300),

    async getList() {
      try {
        this.loading = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...(this.query ? { search: this.query } : {}),
          },
          page: {
            number: this.pagination.currentPage,
            size: this.pagination.perPage,
          },
          include: "organization,spot,customer,order,createdBy",
        };

        params = {
          ...params,
          filter: {
            ...params.filter,
            in_status: [
              BOOKING_STATUS_GUARANTEED,
              BOOKING_STATUS_NOT_GUARANTEED,
              BOOKING_STATUS_FINISHED,
              BOOKING_STATUS_PENDING,
              BOOKING_STATUS_ARRIVED,
            ],
          },
        };

        if (this.filterOrganization) {
          params = {
            ...params,
            filter: { ...params.filter, organization: this.filterOrganization },
          };
        }
        if (this.selectedOrganization) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              organization: this.selectedOrganization,
            },
          };
        }

        await this.$store.dispatch("bookings/list", params);
        this.bookings = this.$store.getters["bookings/list"];
        this.total = this.$store.getters["bookings/listTotal"];
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    canCheckInBooking(booking) {
      return (
        !!booking &&
        [BOOKING_STATUS_GUARANTEED, BOOKING_STATUS_NOT_GUARANTEED].includes(
          booking.status
        )
      );
    },

    canCheckOutBooking(booking) {
      return !!booking && [BOOKING_STATUS_ARRIVED].includes(booking.status);
    },

    async deleteBooking(id) {
      const confirmation = await swal.fire({
        text: this.$t("BOOKINGS.DELETE_THIS_BOOKING"),
        type: "question",
        customClass: {
          popup: "delete-popup",
        },
        buttonsStyling: false,
        showCancelButton: true,
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonText: this.$t("COMMON.YES"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("bookings/destroy", id);
          await this.getList();
          this.$notify({
            type: "success",
            message: this.$t("BOOKINGS.BOOKING_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    checkInBooking(booking) {
      this.$emit("onCheckInBooking", booking);
    },

    checkOutBooking(booking) {
      this.$emit("onCheckOutBooking", booking);
    },

    sortChange({ prop, order }) {
      if (order === "descending") {
        this.sort = `-${prop}`;
      } else {
        this.sort = `${prop}`;
      }
      this.getList();
    },

    selectRow(row, ...args) {
      const target = args[1]?.target;

      if (target && target.classList.contains("fa-ellipsis-v")) {
        return false;
      }

      this.$emit("onViewBooking", row);
    },
  },
};
</script>
