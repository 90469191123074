<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ $t("CUSTOMERS.CUSTOMER_DETAILS") }}</h3>

    <div class="col-12">
      <div class="mt-3">
        <h3 class="text-orange">{{ $t("CUSTOMERS.CUSTOMER_INFORMATION") }}</h3>

        <div class="row px-2" v-if="booking.customer">
          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("CUSTOMERS.LAST_NAME") }}
            </dt>
            <dd class="">
              <div>
                {{ booking.customer.lastname }} {{ booking.customer.firstname }}
              </div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("CUSTOMERS.EMAIL") }}
            </dt>
            <dd class="">
              <div>{{ booking.customer.email }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("CUSTOMERS.ADDRESS") }}
            </dt>
            <dd class="">
              <div>{{ booking.customer.address }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("COMMON.STATE") }}
            </dt>
            <dd class="">
              <div>{{ booking.customer.state }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("COMMON.COUNTRY") }}
            </dt>
            <dd class="">
              <div>{{ booking.customer.country }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("COMMON.ZIPCODE") }}
            </dt>
            <dd class="">
              <div>{{ booking.customer.zipcode }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("COMMON.PHONE") }}
            </dt>
            <dd class="">
              <div>{{ booking.customer.phone }}</div>
            </dd>
          </dl>

          <dl
            class="col-md-6 p-0 pb-4"
            v-if="booking.customer.other_phones?.length"
          >
            <dt class="font-weight-bold">
              {{ $t("COMMON.OTHER_PHONES") }}
            </dt>
            <dd class="">
              <div>{{ booking.customer.other_phones?.join(", ") }}</div>
            </dd>
          </dl>

          <dl class="col-md-6 p-0 pb-4">
            <dt class="font-weight-bold">
              {{ $t("COMMON.LOCALE") }}
            </dt>
            <dd class="">
              <div>{{ booking.customer.phone }}</div>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "booking-view-customer",

  props: ["booking"],

  components: {},

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {},

  mounted() {},

  watch: {
    booking(booking) {},
  },
};
</script>

<style lang="scss">
.text-orange {
  --darken-primary: #{darken(#ef8152, 10%)};
  color: --darken-primary;
}
</style>
