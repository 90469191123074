<template>
  <div class="container-fluid">
    <span class="resize-loading" v-if="!booking">
      <span class="loader"></span>
    </span>
    <div v-if="booking" class="elite-tabs-wrapper">
      <div class="view-details-header">
        <div class="view-details-header-left">
          <h2>{{ $t("BOOKINGS.BOOKING_DETAILS") }}</h2>
          <div class="kw-breadcrumb">
            <ul>
              <li>
                <img src="/img/kw-home.svg" alt="icon" />
                <span>{{ $t("BOOKINGS.BOOKINGS_LIST") }}</span>
              </li>
              <li>
                <span>{{ booking.code }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="view-details-header-right">
          <button class="edit" @click="checkInBooking" v-if="canCheckInBooking">
            <!-- <i class="far fa-trash-alt"></i> -->
            <span>{{ $t("BOOKINGS.CHECK_IN") }}</span>
          </button>
          <button
            class="edit"
            @click="checkOutBooking"
            v-if="canCheckOutBooking"
          >
            <!-- <i class="far fa-edit"></i> -->
            <span>{{ $t("BOOKINGS.CHECK_OUT") }}</span>
          </button>
          <button class="edit"
              @click="onEditBooking"
              v-if="$currentUserCan($permissions.PERM_EDIT_BOOKINGS)">
            <i class="far fa-edit"></i>
            <span>{{ $t("COMMON.EDIT_INFOS") }}</span>
          </button>
        </div>
      </div>
      <tabs
        fill
        class="flex-column flex-md-row"
        tabNavWrapperClasses="nav-wrapper"
        tabNavClasses="nav elite-tabs"
        value="booking"
      >
        <tab-pane title="booking" id="1" :active="true">
          <span slot="title">
            <!-- <i class="ni ni-cloud-upload-96"></i> -->
            {{ $t("COMMON.SUMMARY") }}
          </span>
          <booking-view-global :booking="booking" @onContractUpdated="get" />
        </tab-pane>
        <tab-pane title="spot" id="2" :active="true">
          <span slot="title">
            {{ $t("COMMON.SPOT") }}
          </span>
          <booking-view-spot :booking="booking" />
        </tab-pane>
        <tab-pane title="customer" id="3" :active="true">
          <span slot="title">
            {{ $t("COMMON.CUSTOMER") }}
          </span>
          <booking-view-customer :booking="booking" />
        </tab-pane>

        <tab-pane title="sales-invoices" id="4" :active="true">
          <span slot="title">
            {{ $t("SALES_INVOICES.SALES_INVOICES") }}
          </span>
          <div class="elite-tabs-wrapper-content">
            <h3>
              {{ $t("SALES_INVOICES.SALES_INVOICES_LIST_SHORT") }}
            </h3>
            <sales-invoice-list-table
                invoice-type="invoices"
                :filter-sales-order="this.booking.orderItem.salesOrder.id"
                :filter-status="[INVOICE_STATUS_PAID, INVOICE_STATUS_VALIDATED, INVOICE_STATUS_DRAFT, INVOICE_STATUS_CANCELED]"
                @onViewSalesInvoice="onViewSalesInvoice"
            />
          </div>
        </tab-pane>

      </tabs>
    </div>
  </div>
</template>

<script>
import {
  Table,
  TableColumn,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  Tooltip,
  Select,
  Option,
  Button,
} from "element-ui";
import { Tabs, TabPane } from "@/components";
import BookingViewGlobal from "../partials/BookingViewGlobal.vue";
import BookingViewSpot from "../partials/BookingViewSpot.vue";
import BookingViewCustomer from "../partials/BookingViewCustomer.vue";
import {
  INVOICE_STATUS_CANCELED,
  INVOICE_STATUS_DRAFT,
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_VALIDATED,
} from "@/constants/invoices";

import {
  BOOKING_STATUS_ARRIVED,
  BOOKING_STATUS_GUARANTEED,
  BOOKING_STATUS_NOT_GUARANTEED,
} from "@/constants/common" ;

import SalesInvoiceListTable from "@/views/Pages/SalesModule/SalesInvoiceManagement/partials/SalesInvoiceListTable.vue";
import {QUERY_ACTIONS_VIEW} from "@/constants/common";

export default {
  layout: "DashboardLayout",

  components: {
    SalesInvoiceListTable,
    [Tooltip.name]: Tooltip,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Select.name]: Select,
    [Option.name]: Option,
    [Button.name]: Button,
    Tabs,
    TabPane,
    BookingViewGlobal,
    BookingViewSpot,
    BookingViewCustomer,
  },

  mixins: [],

  props: {
    bookingId: {
      type: [String, Number],
      required: true,
    },
  },

  data() {
    return {
      booking: null,
      salesInvoice: null,
      loading: false,
      INVOICE_STATUS_DRAFT: INVOICE_STATUS_DRAFT,
      INVOICE_STATUS_CANCELED: INVOICE_STATUS_CANCELED,
      INVOICE_STATUS_VALIDATED: INVOICE_STATUS_VALIDATED,
      INVOICE_STATUS_PAID: INVOICE_STATUS_PAID,
    };
  },

  computed: {
    canCheckInBooking() {
      return (
        !!this.booking &&
        [BOOKING_STATUS_GUARANTEED, BOOKING_STATUS_NOT_GUARANTEED].includes(
          this.booking?.status
        )
      );
    },

    canCheckOutBooking() {
      return (
        !!this.booking && [BOOKING_STATUS_ARRIVED].includes(this.booking.status)
      );
    },
  },

  watch: {},

  created() {
    this.get();
  },

  methods: {
    async get() {
      try {
        await this.$store.dispatch("bookings/get", this.bookingId);
        this.booking = this.$store.getters["bookings/booking"];
        this.$emit("onBookingViewing", this.booking.orderItem.salesOrder.id);
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    onViewSalesInvoice(invoice)
    {
      let routeParams = {
        name: "List SalesInvoices",
        query: { id: invoice.id, action: QUERY_ACTIONS_VIEW},
      };

      let route = this.$router.resolve(routeParams);

      history.pushState({}, null, route.href);

      this.$router.push(routeParams) ;
    },

    checkInBooking() {
      this.$emit("onCheckInBooking", this.booking);
    },

    checkOutBooking() {
      this.$emit("onCheckOutBooking", this.booking);
    },

    onEditBooking() {
      this.$emit("onEditBooking", this.booking);
    },
  },
};
</script>
