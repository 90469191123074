<template>
  <span>{{ equipments.join(", ") }}</span>
</template>

<script>
export default {
  name: "allowed-equipments",

  props: {
    value: {
      type: Array,
      default: [],
    },
  },

  computed: {
    equipments: function () {
      return this.value.map((value) =>
        this.$t(`SPOTS.ALLOWED_EQUIPMENT_${value}`)
      );
    },
  },
};
</script>

<style lang="scss" scoped></style>
